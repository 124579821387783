<template>
  <div class="animated fadeIn">
    <div id="cards" v-if="showCards">
      <div id="card-group">
        <div id="card-cadastro">
          <div class="card-top">
            <img src="../assets/img/iconecadastro.png" alt="Ícone do cadastro">
            <h4> Cadastro </h4>
          </div>
          <div class="card-bottom">
            <hr>
            <div>
              <a :href="getWhatsapp('cadastro')"  target="_blank" id="link_whatsapp_cadastro">
                <img src="../assets/img/iconewhats.png" alt="Ícone do telefone whatsapp do cadastro">
              </a>
              <span> Cadastre e gerencie clientes na sua base. </span>
            </div>
          </div>
        </div>
        <div id="card-financeiro">
          <div class="card-top">
            <img src="../assets/img/iconefinanceiro.png" alt="Ícone do financeiro">
              <h4> Financeiro </h4>              
          </div>
          <div class="card-bottom">
            <hr>
            <div>
              <a :href="getWhatsapp('financeiro')"  target="_blank" id="link_whatsapp_financeiro">
                <img src="../assets/img/iconewhats.png" alt="Ícone do telefone whatsapp do financeiro">
              </a>
              <span> Recebimento de prêmios e emissão de notas fiscais. <br/> Solicitação de boletos de clientes. </span>
            </div>
          </div>
        </div>
        <div id="card-suporte">
          <div class="card-top">
            <img src="../assets/img/iconesuporte.png" alt="Ícone do suporte">
              <h4> Suporte </h4>
          </div>
          <div class="card-bottom">
            <hr>
            <div>
              <a :href="getWhatsapp('suporte')" target="_blank" id="link_whatsapp_suporte">
                <img src="../assets/img/iconewhats.png" alt="Ícone do telefone whatsapp do suporte">
              </a>
              <span> Tire suas dúvidas através do nosso suporte técnico. </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  #cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    margin-top: 9em;
  }

  #card-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    width: 100%;
  }

  #card-cadastro {
    display: flex;
    flex-wrap: wrap;
    background: url("../assets/img/boxcadastro.png");
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 1%;
    width: 300px;
    height: 375px;
  }

  #card-financeiro {
    display: flex;
    flex-wrap: wrap;
    background: url("../assets/img/boxfinanceiro.png");
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 1%;
    width: 305px;
    height: 375px;
  }

  #card-suporte {
    display: flex;
    flex-wrap: wrap;
    background: url("../assets/img/boxsuporte.png");
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 1%;
    width: 300px;
    height: 375px;
  }

  .card-top, .card-bottom {
    display: flex;
    flex-direction: row;
    color: white;
  }

  .card-top {
    margin-left: 3em;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .card-bottom {
    padding-top: 2em;
    margin-top: 3em;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 15px;
  }

  .card-top img {
    display: flex;
    margin-right: 0.5em;
  }

  .card-top h4 {
    display: flex;
    margin: 0;
    font-weight: normal;
  }

  .card-bottom > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }

  .card-bottom span {
    width: 46%;
    line-height: normal;
    margin-left: 0.5em;
    font-size: 16px;
    height: 106px;
  }

  .card-bottom hr {
    border-top: 1px solid rgb(255, 115, 0);
    width: 68%;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
</style>

<script>

import Revenda from "../services/revenda";

export default {
  name: "atendimento",
  data()
  {
    return{
      showCards: false,

    }
  },
  mounted()
  {
    this.getDataService();
  },
  methods: 
  {
    getDataService(){
      Revenda.getDataService()
        .then( response => {
            this.whatsapp = response.data.data;
            this.showCards = true;
          }
        );
    }
  },
  computed: {
    getWhatsapp(){
      return tipo => {
        return this.$store.state.configPadrao.linkWhatsapp + this.whatsapp[`whatsapp_${tipo}`];
      }
    }
  }
};
</script>
